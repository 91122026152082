html {
  background-color: #2f3738;
}

body {
  background: url("./images/Big-Ts-Snappers-Board.jpg")
    no-repeat center fixed;
  background-size: 100vw 100vh;
  color: white;
  font-family: "Roboto Condensed", Verdana, Geneva, Tahoma, sans-serif;
}
.center {
  text-align: center;
}
button, input, optgroup, select, textarea {
  font-family: "Roboto Condensed", Verdana, Geneva, Tahoma, sans-serif;
}
.header {
  display:inline-flex;
}
.header .logo-img {
  height: 4rem;
  margin: auto;
  margin-left: 2rem;
  margin-right: 2rem;
}
.logo-img {
  height: 6rem;
  margin: 2rem;
}
/* =========================== NAV Styling =========================== */
.nav-wrapper {
  background-color: #2f3738;
}
nav {
  background-color: #2a2d2e;
}
nav ul a {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.8);;
}
nav .brand a {
  height: -webkit-fill-available;
}
.cursive {
  display: inherit;
  font-family: "Rye", "Sans";
}
.btn-link {
  background: #000000;
}
.main-btn {
  height: 4rem;
  background: #00adff;
}
.black-txt {
  color: black;
  font-weight: 700;
  font-size: 1.7rem;
  vertical-align: text-top;
}
.fas {
  line-height: inherit;
}
.fa-fish:before {
  margin-right: 10px;
}
.brand {
  height: 100%;
  margin-left: 1%;
  display: inline-block;
  padding: 0;
}
nav .nav-logo {
  height: 42px;
}
nav .btn-link {
  position: relative;
  float: right;
  margin-top: 0.5%;
  margin-right: 35px;
  height: 40px;
  width: 135px;
  background: black;
}
nav .btn-link.sidenav-trigger{
  margin: 0;
  height: 6rem;
  width: 73%;
}
nav .brand:active {
  background: #2bbbad !important;
}nav .brand:focus {
  background: #2bbbad !important;
}
.btn i {
  font-size: 141%;
  line-height: 326%;
}
.text-secondary {
  position: absolute;
  background: black;
  left: 9px;
  right: 9px;
  height: -webkit-fill-available;
}

/* =========================== SIDENAV Styling =========================== */
.sidenav-trigger {
  height: 100%;
}
.sidenav-trigger:hover {
  box-shadow: 0 0 10px 5px rgb(60 165 194 / 80%);
}
.nav-container {
  height: 6rem;
}
nav .logo-img {
  margin: 0;
  height: 6rem;
  float: left;
  width: 27%;
  cursor: pointer;
}
.sidenav {
  background-color: #2f3738;
}
.sidenav .waves-effect {
  display: block;
  background-color: black;
}
.sidenav .waves-effect:hover {
  background-color: black;
}.sidenav .waves-effect:active {
  background-color: #2bbbad;
}.sidenav .waves-effect:focus {
  background-color: #2bbbad;
}
.sidenav-close i {
  float: right;
  margin-right: 10%;
  color: rgba(255, 255, 255, 0.8);
}
.material-icons{
  display: inline-flex;
  vertical-align: top;
}
/* .menu-txt {

} */

/* =========================== LOGIN styling =========================== */
input {
  color: white;
  max-width: 300px;
  margin: auto;
  width: 50%;
}
.login-btn, .create-btn {
  background: #00adff;
  margin-top: 2rem;
}
.signin-nav {
  display: block;
  background-color: transparent;
  margin-top: 2rem;
}
/* main tag */
main {
  min-height: 70vh !important;
}
/* container styling */
.mainContent {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  padding: 1em 1em 3em 1em;
  margin-top: 2em;
  width: 85% !important;
}
.form-container {
  width: 100% !important;
}
h1 {
  color: gold;
}
h2, h6 {
  color: gold !important;
}
h6 {
  background-color: #647678;
  padding: 0.75em 0.75em 0.75em 0.75em;
  width: 85%;
  border-radius: 5px;
  opacity: 0.8;
}
.mainContent h6 {
  background-color: #515d60d6;
}
img:hover {
  box-shadow: 0 0 10px 5px rgba(60, 165, 194, 0.8);
}
.row .col.dead-link {
  height: 30rem;
  width: 42rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  float: none;
}
.email-helper, .username-helper, .password-helper, .number-helper {
  color:firebrick;
}
/* =========================== Calendar and Table Styling =========================== */
.react-calendar {
  cursor: default;
}
.react-calendar__navigation {
  height: 50px;
  font-size: 1.5rem;
}
.react-calendar__navigation .react-calendar__navigation__arrow {
  width: 50px;
}
.react-calendar__navigation .react-calendar__navigation__label {
  font-weight: 700;
}

.react-calendar__month-view__weekdays div {
  background: white;
  color: black;
  border: 4px solid black;
  font-size: 1.5rem;
}
abbr[title] {
  text-decoration: none;
}

.react-calendar__month-view__days button {
  max-width: 100px;
  min-height: 75px;
  font-size: 2rem;
}
.react-calendar__month-view__days button abbr {
  padding-inline: 3rem;
}
.react-calendar__navigation .react-calendar__navigation__arrow, .react-calendar__navigation .react-calendar__navigation__label,
.react-calendar__month-view__days button  {
  cursor: pointer;
}

/* =========================== CAROUSEL Styling =========================== */
.awssld__bullets {
  bottom: auto;
}

.awssld__controls button:active {
  background-color: transparent;
}
.awssld__controls button:focus {
  background-color: transparent;
}

.awssld__content > img, .awssld__content > video {
  object-fit: contain;
}

/* =========================== MODAL Styling =========================== */

.ReactModal__Content {
  background-color: #000000a3 !important;
  border: 4px SOLID red !important;
  border-radius: 55px !important;
  animation: 1s ease 1s reverse both paused slidein !important;
}
.ReactModal__Content:hover {
  border: 3px SOLID blue !important;
  border-radius: 55px !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
.ReactModal__Content {
  background: darkgrey;
}

.modal-text {
  font-size: 1.5rem;
  color: #00f3ff;
  margin-bottom: 2rem;
}
.schedule-btns {
  color: white;
}
.yes-btn {
  font-size: 1.5rem;
  background-color: #00fc13;
  margin-right: -1rem;
  border-radius: 4px;
}
.cancel-btn {
  font-size: 1.5rem;
  background: #ff2f00; 
  margin-left: 3rem;
  border-radius: 4px;
  font-size: 22px;
}

/* =========================== Account Page Styling =========================== */
.date {
  text-decoration: underline;
}
[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
  position:relative; 
  opacity: 1;
  pointer-events: none;
}
.dates-table {
  background: #515d6091;
  width: 70%;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.dates-table label {
  text-align: left;
  display: block;
  font-size: 1rem;
  color: white;
  cursor: pointer;
}
.dates-table .styled-checkbox p.center {
  cursor: default;
  margin: 20px;
  font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
  border-bottom: none;
}
.dates-table .styled-checkbox p.center span.date{
  cursor: pointer;
}
.dates-table p {
  cursor: pointer;
  margin: 20px;
  font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
  border-bottom: 1px dashed white;
}
.dates-table input {
  margin:0 12px 0 0;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.account-info-header {
  padding-top: .5em;
  border-top: 1px solid dodgerblue;
}
.account-info {
  background: #515d6091;
  width: 70%;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.info-text {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background:black;
}
.date-text {
  color: #00ff72;
}
.phone-text a {
  color: #fbff00;
}
.modal-text .phone-text a {
  color: #ff00eb;
}
.email-text a {
  color: gold;
}
.row {
  border-bottom: 1px dashed white;
  border-radius: 3%;
}
.column {
  float: left;
  width: 50%;
}
.column span.column-label {
  float: right;
  padding-right: 2em;
}
.column span.column-info {
  float: left;
}
.column .btn {
  margin-bottom: 20px;
}

.change-password-form {
  background: white;
  border: 4px solid black;
  border-radius: 4px;
}
.change-password-form label, .change-password-form input {
  color: black;
}

/* =========================== RATES PAGE STYLING =========================== */

.rates-page {
  max-width: 100%;
}
.rates-page .container {
  max-width: 100%;
  width: 95% !important;
}
.rates-section {
  width: 95%;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card {
  display: inline-block;
  border: 1px solid aqua;
  margin: .5rem 2.5rem 1rem 2.5rem;
  padding: 1rem;
  background-color: #515d6099;
  border-radius: 18%;
}
.img-wrap {
  margin: auto;
  border-style: solid;
  border-radius: 50%;
  height: 11rem;
  width: 11rem;
  margin-bottom: 2rem;
}
.img-border {
  position: relative;
}
/* .half-day-img {
  background: url("./images/Red-Grouper.jpg")
  no-repeat center;
}
.full-day-img {
  background: url("./images/Black-Grouper.JPG")
  no-repeat center;
} */
.contact-img {
  background: url("./images/Red-Snapper.jpg")
  no-repeat center;
}
.day-img {
  background-size: 10rem 10rem;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  position: absolute;
  top: 0.25rem;
  right: 0.3rem;
  transition: ease 0.3s;
}
.day-img:hover {
  height:9rem;
  width: 9rem;
  top: 0.7rem;
  right: 0.7rem;
  cursor: pointer;
}
.day-img:focus {
  height:9rem;
  width: 9rem;
  top: 0.7rem;
  right: 0.7rem;
}
.btn-wrap {
  margin-bottom: 2rem;
}
#date-input {
  background-color: #fff !important;
  border-radius: 6px;
  font-size: x-large;
  align-content: center;
  text-align-last: center;
  color: #000;
  margin-bottom: 2rem !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  margin-right: 1rem;
  font-size: 50%;
}
/* =========================== Captn and Boat Page Styling =========================== */
.main-img {
  width: 40%;
  height: auto;
}
.page-head {
  color: gold;
}
.img-txt {
  font-size: 2rem;
  background:black
}

/* =========================== LOADER Styling =========================== */
.loader {
  background:rgb(0, 0, 0);
  background:rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 50%;
  left: 40vw;
}

/* =========================== FOOTER Styling =========================== */
#footer {
  background-color: #647678;
  max-height: 5%;
  margin-top: 60px;
}

#footer p {
  font-weight: 300;
}
#footer a {
  color: lightblue;
}

footer i {
  /* width: 175px; */
  font-size: 24px;
  margin-top: 0.5em;
}
.text-28 {
  font-size: 28px;
}

/* =========================== MEDIA Queryies =========================== */
@media only screen and (max-width: 993px) {
  body {
    background-size: cover;
  }
  .mainContent {
    width: 100% !important;
    padding: 0.2rem;
    margin-top: 5em;
  }
  .react-calendar__month-view__days button abbr {
    padding-inline: 0.3rem;
  }
  .react-calendar__month-view__days button {
    max-width: 100px;
    min-height: 75px;
    font-size: 1.5rem;
  }
  input {
    width: 17%;
  }
  .react-calendar__navigation .react-calendar__navigation__label {
    font-size: 1.3rem;
  }
  .rates-section, .account-info, .dates-table {
    width: 95%
  }
  .header {
    display:block;
  }
}

/* media queries for navbar buttons */
@media only screen and (min-width: 1260px) {
  .hide-on-large-and-up {
      display: none !important;
  }
  .hide-on-med-and-down {
    display: auto !important;
  }
}
@media only screen and (max-width: 1260px) {
  .hide-on-large-and-up {
      display: auto !important;
  }
  .hide-on-med-and-down {
    display: none !important;
  }
}




/* ===============================    MISC STILL FIGURING OUT WHERE TO PLACE ========================================== */
/* const customStyles = {
  overlay: {
    zIndex:  '100',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'darkgrey',
    animation             : 'fadeIn ease 10s',
    textAlign             : 'center',
    animation             : 'fadeOut ease 60s'
  }
}; */
/* =========================== MODAL STYLING ================================== */